import axios from 'axios'

export function app (url, params, resolveType, type = 'post') {
  console.log('url:', url)
  console.log('params:', params)
  return new Promise((resolve, reject) => {
    // 开发环境需要,正式环境需要代理；预发布和测试环境
    const baseurl = ['development', 'production'].includes(process.env.VUE_APP_ENV) ? '/baseurl' : ''
    console.log('运行环境：', process.env.NODE_ENV, ',重命名环境名：', process.env.VUE_APP_ENV)
    axios[type](baseurl + url, params, {
      headers: {
        'auth-token': window.localStorage.getItem('auth_token')
      }
    }).then(res => {
      if (res.data.code === '0') {
        if (resolveType && resolveType === 1) {
          resolve(res.data)
        } else {
          resolve(res.data.data)
        }
      } else if (res.data.code === '-3') {
        console.log('请先登录再进行操作,体验账号请重新登录')
      } else {
        reject(res.data)
      }
    }).catch(err => {
      console.error(err)
    })
  })
};
