import Vue from 'vue'
// import { Form, FormItem, Input } from 'element-ui'

// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Input)

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/element-variables.scss'

Vue.use(ElementUI)
