import Vue from "vue";
import {
  Checkbox,
  CheckboxGroup,
  NavBar,
  Image,
  Grid,
  GridItem,
  Swipe,
  SwipeItem,
  Button,
  Toast,
  Form,
  Field,
  RadioGroup,
  Radio,
  Dialog,
  Loading,
  Calendar,
  Search,
  List,
  DatetimePicker,
  Popup,
  Tab,
  Tabs,
  NumberKeyboard,
  Icon,
  Empty,
  Stepper,
  Cascader,
  Picker,
  ImagePreview
} from "vant";
Vue.use(ImagePreview);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Cascader);
Vue.use(Empty);
Vue.use(Stepper);
Vue.use(Form);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Calendar);
Vue.use(Search);
Vue.use(List);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(NumberKeyboard);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Image);
Vue.use(NavBar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Icon);
Vue.use(Picker);
