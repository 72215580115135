'use strict'

import Vue from 'vue'
import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30 * 1000, // Timeout
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
  // withCredentials: true, // Check cross-site Access-Control
}

const _mangeAxios = axios.create(config)

_mangeAxios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_mangeAxios.interceptors.response.use(
  function (response) {
    // Do something with response data
    console.log(response, 'response')
    if (response.data.code === '0') {
      return response
    } else {
      return Promise.reject(response.data)
    }
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

export function mangeApi (url, params) {
  return _mangeAxios.post(url, params)
}

Plugin.install = function (Vue, options) {
  Vue.mangeAxios = _mangeAxios
  window.mangeAxios = _mangeAxios

  Object.defineProperties(Vue.prototype, {
    mangeAxios: {
      get () {
        return _mangeAxios
      }
    },
    $mangeApi: {
      get () {
        return mangeApi
      }
    },
    $mangeAxios: {
      get () {
        return _mangeAxios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
