import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    USER_INFO: {} // 用户信息，(登录后||App跳转过来)存储
  },
  mutations: {
    setUserInfo (state, obj) {
      state.USER_INFO = { ...obj }
      window.sessionStorage.setItem('Base_Info', JSON.stringify({
        user_code: obj.user_code,
        org_id: obj.org_id,
        mobile: obj.mobile
      }))
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    USER_INFO: state => state.USER_INFO
  }
})
