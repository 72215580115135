import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () =>
      import(/* webpackChunkName: "Index" */ '@/pages/Index/Index.vue')
  },
  {
    path: '/toutiao',
    name: 'TouTiao',
    component: () =>
      import(/* webpackChunkName: "Toutiao" */ '@/pages/Toutiao/Toutiao.vue')
  },
  {
    path: '/join_banner',
    name: 'JoinBanner',
    component: () =>
      import(
        /* webpackChunkName: "JoinBanner" */ '@/pages/JoinBanner/JoinBanner.vue'
      )
  },
  {
    path: '/purchase_banner',
    name: 'PurchaseBanner',
    component: () =>
      import(
        /* webpackChunkName: "PurchaseBanner" */ '@/pages/PurchaseBanner/PurchaseBanner.vue'
      )
  },
  {
    path: '/sale_banner',
    name: 'SaleBanner',
    component: () =>
      import(
        /* webpackChunkName: "SaleBanner" */ '@/pages/SaleBanner/SaleBanner.vue'
      )
  },
  {
    path: '/kefu',
    name: 'KeFu',
    component: () =>
      import(/* webpackChunkName: "KeFu" */ '@/pages/KeFu/KeFu.vue')
  },
  {
    path: '/download',
    name: 'Download',
    component: () =>
      import(/* webpackChunkName: "Download" */ '@/pages/Download/Download.vue')
  },
  {
    path: '/downloadCenter',
    name: 'DownloadCenter',
    component: () =>
      import(
        /* webpackChunkName: "Download" */ '@/pages/Download/DownloadCenter.vue'
      )
  },
  {
    path: '/form/market',
    name: 'FormMarket',
    component: () =>
      import(/* webpackChunkName: "Download" */ '@/pages/Form/Market.vue')
  },
  {
    path: '/form/StatisticsList',
    name: 'formList',
    component: () =>
      import(
        /* webpackChunkName: "Download" */ '@/pages/Form/StatisticsList.vue'
      )
  },
  {
    path: '/baise',
    name: 'Baise',
    component: () =>
      import(/* webpackChunkName: "Baise" */ '@/pages/Baise/Baise.vue')
  },
  {
    path: '/hongbao',
    name: 'Hongbao',
    component: () =>
      import(/* webpackChunkName: "Baise" */ '@/pages/Hongbao/Hongbao.vue')
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: () =>
      import(
        /* webpackChunkName: "Baise" */ '@/pages/Withdrawal/withdrawal.vue'
      )
  },
  {
    path: '/withdrawalResult',
    name: 'WithdrawalResult',
    component: () =>
      import(
        /* webpackChunkName: "Baise" */ '@/pages/Withdrawal/withdrawalResult.vue'
      )
  },
  {
    path: '/pay',
    name: 'VipPay',
    component: () =>
      import(/* webpackChunkName: "VipPay" */ '@/pages/Vip/pay.vue')
  },
  {
    path: '/pay/productPayResult',
    name: 'productPayResult',
    component: () =>
      import(
        /* webpackChunkName: "VipPayResult" */ '@/pages/Vip/productPayResult.vue'
      )
  },
  {
    path: '/pay/posterPayResult',
    name: 'posterPayResult',
    component: () =>
      import(
        /* webpackChunkName: "VipPayResult" */ '@/pages/Vip/posterPayResult.vue'
      )
  },
  {
    path: '/pay/result',
    name: 'VipPayResult',
    component: () =>
      import(/* webpackChunkName: "VipPayResult" */ '@/pages/Vip/payResult.vue')
  },
  {
    path: '/articleProduct',
    name: 'articleProduct',
    component: () =>
      import(
        /* webpackChunkName: "Baise" */ '@/pages/articleProduct/articleProduct.vue'
      )
  },
  {
    path: '/articleHome',
    name: 'articleHome',
    component: () =>
      import(/* webpackChunkName: "Baise" */ '@/pages/articleProduct/home.vue')
  },
  {
    path: '/agentApply',
    name: 'AgentApply',
    component: () =>
      import(
        /* webpackChunkName: "Baise" */ '@/pages/RecruitmentAgencies/apply.vue'
      )
  },
  {
    path: '/agentPoster',
    name: 'AgentPoster',
    component: () =>
      import(
        /* webpackChunkName: "Baise" */ '@/pages/RecruitmentAgencies/poster.vue'
      )
  },
  {
    path: '/agentUnderReview',
    name: 'AgentUnderReview',
    component: () =>
      import(
        /* webpackChunkName: "Baise" */ '@/pages/RecruitmentAgencies/underReview.vue'
      )
  },
  {
    path: '/amountDetail',
    name: 'AmountDetail',
    component: () =>
      import(/* webpackChunkName: "Baise" */ '@/pages/Amount/amountDetail.vue')
  },
  // 司机端登录
  {
    path: '/driverLogin',
    name: 'driverLogin',
    component: () =>
      import(/* webpackChunkName: "Baise" */ '@/pages/Driver/login.vue')
  },
  // 司机端物流单表
  {
    path: '/driver',
    name: 'driver',
    component: () =>
      import(/* webpackChunkName: "Baise" */ '@/pages/Driver/index.vue')
  },
  // 司机端物流单详情
  {
    path: '/logistics',
    name: 'logistics',
    component: () =>
      import(/* webpackChunkName: "logistics" */ '@/pages/Driver/logistics.vue')
  },
  // 商品详情
  {
    path: '/publicProductDetail',
    name: 'publicProductDetail',
    component: () =>
      import(
        /* webpackChunkName: "logistics" */ '@/pages/product/publicProductDetail.vue'
      )
  },
  // 确认订单
  {
    path: '/confirmOrder',
    name: 'confirmOrder',
    component: () =>
      import(
        /* webpackChunkName: "logistics" */ '@/pages/product/confirmOrder.vue'
      )
  },
  // 确认订单
  {
    path: '/orgDetail',
    name: 'orgDetail',
    component: () =>
      import(
        /* webpackChunkName: "logistics" */ '@/pages/product/orgDetail.vue'
      )
  },
  // 确认订单
  {
    path: '/posterDetail',
    name: 'posterDetail',
    component: () =>
      import(
        /* webpackChunkName: "logistics" */ '@/pages/poster/posterDetail.vue'
      )
  },
  // 3d vr
  {
    path: '/vrScene',
    name: 'vrScene',
    component: () =>
      import(
        /* webpackChunkName: "vrScene" */ '@/pages/vrScene'
      )
  },
  // 一件代发
  {
    path: '/deliverPoster',
    name: 'deliverPoster',
    component: () =>
      import(
        /* webpackChunkName: "Baise" */ '@/pages/IssuedOnBehalf/poster.vue'
      )
  },
  // 视频-广告
  {
    path: "/videoAd",
    name: "videoAd",
    component: () =>
      import(
        /* webpackChunkName: "videoAd" */ "@/pages/ad/video"
      )
  }
]

const router = new VueRouter({
  routes
})

export default router
