import { getObsTempToken } from '@/api/public'
import Vue from 'vue'

var obsBucket = 'bcmarket' // 桶名
var obsServer = 'https://obs.cn-south-1.myhuaweicloud.com' // 上传地址
var obsHost = `https://${obsBucket}.obs.cn-south-1.myhuaweicloud.com/`

/* 获取obs对象 */
function getObsClient () {
  return new Promise((resolve, reject) => {
    if (!window.ObsClient) {
      return reject('功能初始化中，请稍后再试')
    }

    if (window.obsClient) {
      resolve(window.obsClient)
    } else {
      getObsTempToken().then(res => {
        window.obsClient = new window.ObsClient({
          access_key_id: res.data.data.access, // 配置AK
          secret_access_key: res.data.data.secret, // 配置SK
          server: obsServer, // 配置服务地址
          security_token: res.data.data.securitytoken,
          timeout: 3000
        })
        resolve(window.obsClient)
      }).catch(() => {
        reject()
      })
    }
  })
}

/* 上传 */
function uploadObs (file) {
  return new Promise((resolve, reject) => {
    // 毫秒时间戳+6位随机数
    const now = new Date()
    const format = (value) => value < 10 ? '0' + value : value
    const folder = '' + now.getFullYear() + format(now.getMonth() + 1) + format(now.getDate()) + '/'
    getObsClient().then(obsClient => {
      const key = `${folder}${Date.now()}${Math.ceil(Math.random() * 600000)}`
      obsClient.putObject({
        Bucket: obsBucket,
        Key: key,
        SourceFile: file
      }, function (err, result) {
        if (err) {
          reject(err)
          console.error('errxx', err)
        } else {
          if (result.CommonMsg.Status < 300) {
            if (result.InterfaceResult) {
              return resolve(`${obsHost}${key}`)
            }
          }

          reject(result)
        }
      })
    }).catch((err) => {
      console.error('err', err)
      reject()
    })
  })
}

Vue.prototype.$uploadObs = uploadObs
